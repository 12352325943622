<template>
    <div>
        <zw-select-group v-model="massMethods"
                         :options="getMassMethods()"
                         name="mass_methods"
                         :label-prefix="labelPrefix"
        ></zw-select-group>
        <b-row>
            <b-col cols="12" v-if="massMethods == 'replace'">
                <label>{{ $t(labelPrefix + 'old_value') }}</label>
                <multiselect
                    v-model="oldVal"
                    :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                    :multiple="false"
                    :custom-label="opt => getCategoryOptions()[opt]">
                </multiselect>
            </b-col>
            <b-col cols="12" v-if="massMethods != 'delete_all'">
                <label>{{ $t(labelPrefix + 'new_value') }}</label>
                <multiselect
                    v-model="inputVal"
                    :options="Object.keys(getCategoryOptions()).map(cat=>parseInt(cat))"
                    :multiple="!(massMethods == 'replace')"
                    :custom-label="opt => getCategoryOptions()[opt]">
                </multiselect>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
        'oldValue': {},
    },
    data() {
        return {
            massMethods: null,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getMassMethods']),
        ...mapGetters('Category', ['getCategoryOptions']),
    },
    mounted() {
        this.$store.dispatch('Category/fetchOptions')
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        oldVal: {
            get() {
                return this.oldValue;
            },
            set(val) {
                this.$emit('input-old', val);
            }
        },
    }
}
</script>